<template>
  <div class="Singer">
    <div class="topNull" v-show="!$route.meta.hideNav"></div>
    <router-view @play="$emit('play', $event)"></router-view>
    <!-- <h1>歌手</h1> -->
    <ul class="singBox" v-show="!$route.meta.singer">
      <sing-list
        v-for="item in artists"
        :key="item.id"
        :id="item.id"
        :item="item"
        @play="$emit('play', $event)"
      >
      </sing-list>
      <loading v-if="isLoading" />
    </ul>
  </div>
</template>
<script>
import SingList from "@/components/SingList";
import Loading from "@/components/Loading";

export default {
  data() {
    return {
      artists: [],
      arId: null,
      page: 0, //记录页码
      perpage: 10, //每页显示10条
      isLoading: true,
      opens: true,
    };
  },
  components: {
    SingList,
    Loading,
  },
  // /artist/list?cat=1001
  created() {
    //获取歌手详情
    this.$axios
      .get(
        `/artist/list?cat=1001&limit=${this.perpage + this.page * this.perpage}`
      )
      .then((a) => {
        this.artists = a.data.artists;
        this.isLoading = false;
        // console.log("artists==>", this.artists);
      });
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      // console.log("scrollHeight", document.documentElement.scrollHeight);
      // console.log("scrollTop", document.documentElement.scrollTop);
      // console.log("clientHeight", document.documentElement.clientHeight);
      let h =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let ch =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (h - top - ch <= 0) {
        console.log("1111111111111111");
        this.isLoading = true;

        // this.loadeMore();
        if (ch + top == h && this.opens) {
          this.isLoading = false;
          this.page++;
          console.log(this.page);
          this.$axios
            .get(
              `/artist/list?cat=1001&limit=${
                this.perpage + this.page * this.perpage
              }`
            )
            .then((a) => {
              this.artists = a.data.artists;
              if (
                this.perpage + this.page * this.perpage >
                a.data.artists.length
              ) {
                this.opens = false;
                this.isLoading = false;
                console.log("已全部加载完毕");
              }
              // console.log("artists==>", this.artists);
            });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.Singer {
  .topNull {
    height: 40px;
  }
  .singBox {
  }
}
</style>