<template>
  <div>
    <li>
      <div class="img">
        <router-link
          tag="img"
          :to="{
            name: 'Singerpage',
            params: {
              id: item.id,
              picUrl: item.picUrl,
            },
          }"
          :src="item.picUrl"
          @play="$emit('play', $event)"
        ></router-link>
        <!-- <img :src="item.picUrl" /> -->
        <p>{{ item.name }}</p>
      </div>
      <div class="content">
        <p v-for="(i, index) in arId[0]" :key="index">
          <i>{{ index + 1 }}. </i>
          <span @click.stop="$emit('play', i)">{{ i.name }} - </span>
          <span @click.stop="$emit('play', i)">{{ i.al.name }}</span>
          <i class="iconfont icon-play" @click.stop="$emit('play', i)"></i>
        </p>
      </div>
    </li>
  </div>
</template>
<script>
export default {
  name: "singBox",
  props: ["id", "item"],
  data() {
    return {
      arId: [],
    };
  },
  created() {
    // console.log(this.id);
    this.$axios.get(`/artist/top/song?id=${this.id}`).then((s) => {
      this.arId.push(s.data.songs.slice(0, 3));

      //   console.log(s.data.songs.slice(0, 3));
      //   console.log(this.arId);
    });
  },
};
</script>
<style lang="less" scoped>
li {
  display: flex;
  border-bottom: 2px solid #fafafa;
  .img {
    padding: 10px;
    img {
      width: 10vh;
      height: 10vh;
      border-radius: 50%;
      border: 1px solid #fafafa;
      box-shadow: 0px 0px 10px #9db5e4;
    }
    p {
      padding: 5px 0;
      text-align: center;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 1; //行数需设置
      -webkit-box-orient: vertical;
    }
  }
  .content {
    flex: 1;
    padding: 10px 0px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    p {
      height: 30px;
      line-height: 30px;
      font-size: 15px;
      color: #282846;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 1; //行数需设置
      -webkit-box-orient: vertical;
      span {
        &:last-of-type {
          font-size: 14px;
          color: gray;
        }
      }
      i {
        font-style: normal;
        font-size: 15px;
        &:last-of-type {
          font-size: 22px;
          position: relative;
          top: 4px;
          left: 12px;
        }
      }
      &:first-of-type {
        i {
          color: #ff4400;
        }
      }
      &:nth-of-type(2) {
        i {
          color: #ff8f66;
        }
      }
      &:last-of-type {
        i {
          color: #ffb499;
        }
      }
    }
  }
}
</style>